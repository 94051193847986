import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import json_config from '@/json/config.json'
import json_meta from "@/json/page_global.json";
import store from '@/store/index.js'
import fadeIn from "@/js/fadeIn.js";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { meta_data: "home" }
  },
  {
    path: '/connexion',
    name: 'Connexion',
    component: () => import(/* webpackChunkName: "Connexion" */ '../views/Connexion.vue'),
    meta: { meta_data: "connexion" },
    children: [
      {
        path: '/connexion/:conn_param',
        name: 'ConnexionParam',
        component: () => import(/* webpackChunkName: "Connexion" */ '../views/Connexion.vue')
      }
    ]
  }, 
  {
    path: '/compte',
    name: 'Compte',
    component: () => import(/* webpackChunkName: "Compte" */ '../views/Compte.vue'),
    meta: { meta_data: "compte" }
  },
  {
    path: '/produits',
    name: 'Produits',
    redirect: '/produits/rgpd',
    meta: { 
      meta_data: "produits",
      param: 'type'
    },
    children: [
      {
        path: '/produits/:type',
        name: 'ProduitsType',
        component: () => import(/* webpackChunkName: "Produits" */ '../views/Produits.vue')
      }
    ],
    component: () => import(/* webpackChunkName: "Produits" */ '../views/Produits.vue')
  },
  {
    path: '/panier',
    name: 'Panier',
    component: () => import(/* webpackChunkName: "Panier" */ '../views/Panier.vue'),
    meta: { meta_data: "panier", param: 'commande'},
    children: [
      {
        path: '/panier/:commande',
        name: 'PanierCommande',
        component: () => import(/* webpackChunkName: "Panier" */ '../views/Panier.vue'),
      }
    ]
  },
  {
    path: '/succes',
    name: 'Succes',
    component: () => import(/* webpackChunkName: "Succes" */ '../views/Succes.vue'),
    meta: { meta_data: "succes"},
  },
  {
    path: '/strategie_rgpd',
    name: 'StrategieRGPD',
    component: () => import(/* webpackChunkName: "StrategieRGPD" */ '../views/StrategieRGPD.vue'),
    meta: { meta_data: "strategie_rgpd" }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue'),
    meta: { meta_data: "contact" }
  },
  {
    path: '/document',
    name: 'Document',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Document.vue'),
    meta: { meta_data: "document", param:"name" },
    redirect: '/document/cgv',
    children: [
      {
        path: '/document/:name',
        name: 'DocumentName',
        component: () => import(/* webpackChunkName: "Panier" */ '../views/Document.vue'),
      }
    ]
  },
  {
    path: '/:NotFound(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/NotFound.vue'),
    meta: { meta_data: "notFound" }
  }
]

var router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  console.log("beforeEach")
    ////////////////////////////
   //Partie pour les metadata//
  ////////////////////////////
  var json_meta_param = to.meta.meta_data
  //Si on est sur une page à paramètre
  if (JSON.stringify(to.params) !== JSON.stringify({})){
    //renvoie 'type' pour les produits par ex
    var param = to.meta.param

    //renvoie 'rgpd' pour le produit rgpd par ex
    var nom = to.params[param]

    if(typeof nom!== "undefined" && nom!=""){
      json_meta_param = json_meta_param + "_" + nom
    }
  }
  if(typeof json_meta[store.state.codeLangue].meta_google[json_meta_param]!=="undefined"){
    document.title = json_meta[store.state.codeLangue].meta_google[json_meta_param].title
    document.querySelector('meta[name="description"]').setAttribute("content", json_meta[store.state.codeLangue].meta_google[json_meta_param].desc)
    //console.log(document.querySelector('meta[name="description"]'))
    //console.log(json_meta[store.state.codeLangue].meta_google[json_meta_param])
  }
  else{
    router.push({ name: 'NotFound'})
  }




    /////////////////////////
   //Partie pour le scroll//
  /////////////////////////
  window.removeEventListener('scroll', fadeIn.addFadeInFalse);
  //Si on change pas de page alors on met un scroll smooth
  if(from.path==to.path){
    //Si on est sur la meme page et qu'on a pas de hash on scroll to top
    if(to.hash==''){
      console.log('if')
      var options={
        top: 0,
        left: 0,
        behavior: 'smooth'
      }
    }
    //Sinon on renvoie au hash
    else{
      console.log('else')
      //118 correspond a la hauteur de la navbar
      var options={
        top: document.querySelector(to.hash).offsetTop-118,
        left: 0,
        behavior: 'smooth'
      }
    }
  }
  //Sinon on scroll au top
  else{
    console.log("else2")
    var options={
      top: 1,
      left: 0,
      behavior: 'auto'
    }
  }

  //On envoie le scroll
  window.scrollTo(options)
  console.log(options)
  console.log(window.scrollY)
  //On passe la page
  console.log("next1")
  next()
  console.log("next2")
  /*
  window.addEventListener('scroll', fadeIn.addFadeInFalse);

  console.log("next")
  //fadeIn.addFadeInFalse
  $('.will-fadeIn').each(function(index) {
    $(this).removeClass("fadeInRight");
    $(this).removeClass("fadeInLeft");
  })
  console.log(window.scrollY)
  console.log(from.path!=to.path)
  if(from.path!=to.path){
    console.log("la on va scroll  up")
    console.log(to)
    window.scrollTo(0, 0)
    //fadeIn.addFadeInFalse
  }
  console.log(window.scrollY)*/
})

router.afterEach((to, from) => {
  console.log("next3")
  window.addEventListener('scroll', fadeIn.addFadeInFalse);
  //fadeIn.addFadeInFalse
  $('.will-fadeIn').each(function(index) {
    $(this).removeClass("fadeInRight");
    $(this).removeClass("fadeInLeft");

    //console.log($('.will-fadeIn')[index])
  })
  console.log(window.scrollY)
  console.log(from.path!=to.path)
  if(from.path!=to.path || (from.path==to.path && to.hash=='')){
    console.log("la on va scroll  up")
    console.log(to)
    window.scrollTo(0, 1)
    window.scrollTo(0, 0)
    //fadeIn.addFadeInFalse
  }
  console.log(window.scrollY)
})

export default router