import { createStore } from 'vuex'
import router from '@/router'
import axios from 'axios';
import json_config from "@/json/config.json";
import fadeIn from "@/js/fadeIn.js";

export default createStore({
  state: {
    erreurConnexion: false,
    currentUser: "",
    oldUser:"",
    panier: [],
    vieuxPanier: [],
    cliquable: false,
    intent: {},
    amount: 1999,
    currency:"eur", 
    payment_method_types: "card",
    test: 0,
    lastClick: false,
    nbStepsPassword: 4,
    codeLangue:"FR",
    flag:"fr_flag.png"
  },
  mutations: {
    LOGOUT_USER(state){
      //state.oldUser={}
      //On efface le user du local storage (format .json)
      //window.localStorage.oldUser = JSON.stringify({});

      state.currentUser={}
      //On efface le user du local storage (format .json)
      window.localStorage.currentUser = JSON.stringify({});
    },

    LOGIN_USER(state, user){

      state.currentUser = user;
      state.tva = state.currentUser.tva

      //On met la date a laquelle il a commencé la session
      state.lastClick = new Date()
      document.cookie = "lastClick=" + new Date()

      //On met le user dans le local storage pour le garder (format .json)
      window.localStorage.currentUser = JSON.stringify(user);
    },

    CHANGER_PANIER(state, nouveauPanier){
      //state.vieuxPanier = JSON.parse(window.localStorage.vieuxPanier);

      state.panier = nouveauPanier
      //On met le panier dans le local storage pour le garder (format .json)
      window.localStorage.panier = JSON.stringify(nouveauPanier);
    },

    RETIRER_ARTICLE(state, index){
      state.panier.splice(index, 1);
      //On retire le produit demandé du local storage
      window.localStorage.panier = JSON.stringify(state.panier)

      //state.vieuxPanier=JSON.parse(window.localStorage.vieuxPanier)
    },

    VIDER_PANIER(state){
      //state.vieuxPanier=[]
      //On efface le panier du local storage (format .json)
      //window.localStorage.vieuxPanier = JSON.stringify([]);

      state.panier=[]
      //On efface le panier du local storage (format .json)
      window.localStorage.panier = JSON.stringify([]);
    },

    DEFINE_INTENT(state, intentParam){
      state.intent = intentParam;
      //On met le user dans le local storage pour le garder (format .json)
      window.localStorage.intent = JSON.stringify(intentParam);
    },

    VIDER_LOCAL_STORAGE(){
      window.localStorage.clear();
    },

    sendToCocart(state, {obj, getters}){

      //Si le panier a changé
      if(obj.cocart){
        state.cliquable=false
        var mesDonnees = new FormData();
        mesDonnees.set("panier", JSON.stringify(state.panier));
        mesDonnees.set("mail", state.currentUser.mail);
        mesDonnees.set("cocart", obj.cocart);

        //Donnéees nécessaires à l'intent
        mesDonnees.set("intent", obj.intentType)
        mesDonnees.set("amount", obj.amount);
        mesDonnees.set("currency", "eur");
        mesDonnees.set("payment_method_types", state.payment_method_types);

        axios({
            method: 'post',
            url: json_config.phpFiles_url + '/cocart.php',
            data: mesDonnees
        })
        .then((result) => result.data)
        .then(result => {
          console.log(obj)
          console.log(result)
          var nouveauPanier = []
    
          if(result.items){
            result.items.forEach(
              element => {
                  nouveauPanier.push({id: element.id, name: element.name, price: parseFloat(element.price), priceAfterDiscount: parseFloat(element.totals.total), title: element.title, quantity: element.quantity.value, meta_data: element.cart_item_data})
              }
            )
            nouveauPanier[0].discount = parseFloat(result.totals.discount_total).toFixed(2)
            nouveauPanier[0].tax = parseFloat(result.totals.total_tax).toFixed(2)
            nouveauPanier[0].coupons = result.coupons

            /*
            //Pour chaque coupon récupéré on va chercher la desc et on la push
            nouveauPanier[0].coupons.forEach((element, index)=>{
              var mesDonnees = new FormData();
              mesDonnees.set("coupon", element.coupon);
              
                axios({
                  method: 'post',
                  url: json_config.phpFiles_url + '/getInfosCoupon.php',
                  data: mesDonnees
                })
                .then((result)=>{
                  console.log("je rentre jusque la")
                  nouveauPanier[0].coupons[index]["description"] = result.data.description
                  console.log(result.data.description)
                  console.log("je sors ici")
                })
            })
            */

          }
          //On envoie le nouveauPanier, le vieux panier est donc mis a jour 
          state.panier = nouveauPanier
          //On met le panier dans le local storage pour le garder (format .json)
          window.localStorage.panier = JSON.stringify(nouveauPanier)
          
          if(obj.updatVieuxPanier){
            //state.vieuxPanier = nouveauPanier
            //window.localStorage.vieuxPanier = JSON.stringify(nouveauPanier);
          }

          if(obj.connexion){
            if(obj.route.params.conn_param){
              obj.router.push('/panier/commande')
            }
            else{
              obj.router.push('./compte')
            }
          }

          if(obj.restart_for_coupon){
            console.log((getters.getPrixPanierCents + getters.getTaxPanierCents))
            var objRestart = { "cocart": "chargerPanier", "intentType": "update", "amount": (getters.getPrixPanierCents + getters.getTaxPanierCents), "router": obj.router, "route": obj.route}
            this.dispatch("sendToCocart", objRestart)
          }
          else{
            state.cliquable=true
          }
        })
      }
      else{
        state.cliquable=true
      }
    },

    //Getters pour les couleurs en hexadecimale selon un pourcentage et 

    GET_RED_HEX(state, obj){
      if(obj.pourcentage>=50){
          return (255-obj.bluehex)*obj.pourcentage/50+obj.bluehex
      }
      else{
          return obj.bluehex
      }
    },

    GET_GREEN_HEX(state, obj){
      if(obj.pourcentage<=50){
          return (255-obj.bluehex)*obj.pourcentage/50+obj.bluehex
      }
      else{
          return obj.bluehex
      }
    },

    fade_In_False(){
      fadeIn.addFadeIn(false)
    }
  },
  actions: {
    logoutUser({commit}){
      commit('LOGOUT_USER');
    },

    loginUser({commit}, user){
      commit('LOGIN_USER', user);
    },

    changerPanier({commit}, nouveaupanier){
      commit('CHANGER_PANIER', nouveaupanier)
    },

    viderPanier({commit}){
      commit('VIDER_PANIER');
    },

    retirerArticle({commit}, index){
      commit('RETIRER_ARTICLE', index)
    },

    defineIntent({commit}, intent){
      commit('DEFINE_INTENT', intent)
    },

    viderLocalStorage({commit}){
      commit("VIDER_LOCAL_STORAGE")
    },

    sendToCocart({commit, getters}, obj){
      commit("sendToCocart", {obj, getters})
    },

    getRedHex({commit}, obj){
      commit('GET_RED_HEX', obj)
    },

    getGreenHex({commit}, obj){
      commit('GET_GREEN_HEX', obj)
    },

    fadeInFalse({commit}){
      commit('fade_In_False')
    },

    async loadUser({commit}){
      let user = JSON.parse(window.localStorage.currentUser);
      commit('LOGIN_USER', user)
    },

    async loadPanier({commit}){
      let panier = JSON.parse(window.localStorage.panier);
      commit('CHANGER_PANIER', panier)
    },

    async loadIntent({commit}){
      let intent = JSON.parse(window.localStorage.intent);
      commit('DEFINE_INTENT', intent)
    }
  },
  modules: {
  },
  getters:{
    goTo: (state) => (hash) => {
      var nav = document.getElementById("nav")
      var nav_height = nav.offsetHeight

      var element = document.getElementById(hash)
      var top = element.offsetTop - nav_height;

      var options={
        top: top,
        left: 0,
        behavior: 'smooth'
      }

      window.scrollTo(options);
    },
    getCookies(){
      var cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((accumulator, [key, value]) => ({ ...accumulator, [key.trim()]: decodeURIComponent(value) }), {});

      return cookies
    },
    
    getCliquable(state){
      return state.cliquable
    },

    getPrixPanier(state){
      var total=0
      state.panier.forEach(
        element => total=total+element.price
      )
      if(state.panier.length>0){
        if(state.panier[0].discount){
          total = total - state.panier[0].discount/100
        }
      }
      return total
    },

    getPrixPanierNoDiscount(state){
      var total=0
      state.panier.forEach(
        element => total=total+element.price
      )
      return total
    },

    getTaxPanier(state){
      /*
      if(state.currentUser.tva==true){
        var total=0
        state.panier.forEach(
            element => total=total+element.tax
        )
        return total
      }
      else{
        return 0
      }*/

      if(state.currentUser.tva==true){
        if(state.panier.length>0){
          if(state.panier[0].tax){
            return state.panier[0].tax/100
          }
          else{
            return 0
          }
        }
        else{
          return 0
        }
      }
      else{
        return 0
      }
    },

    getPrixPanierCents(state, getters){
      var total = getters.getPrixPanier
      return Math.round(total*100)
    },

    getTaxPanierCents(state, getters){
      var total = getters.getTaxPanier
      return Math.round(total*100)
    },

    getTTCPanier(state, getters){
      return Number(getters.getPrixPanier + getters.getTaxPanier).toFixed(2)
    },

    getRedHex: (state) => (obj) => {
      if(obj.pourcentage>=50){
          return (255-obj.bluehex)*(100-obj.pourcentage)/50+obj.bluehex
      }
      else{
          return 255
      }
    },

    getGreenHex: (state) => (obj) => {
      if(obj.pourcentage<=50){
          return (255-obj.bluehex)*obj.pourcentage/50+obj.bluehex
      }
      else{
          return 255
      }
    },

    getPasswordStrength: (state) => (password) => {
      var strength = 0;

      /*length 5 characters or more*/
      if(password.length >= 5) {
        strength++;
      }

      /*contains lowercase characters*/
      if(password.match(/[a-z]+/)) {
        strength++;
      }

      /*contains digits*/
      if(password.match(/[0-9]+/)) {
        strength++;
      }

      /*contains uppercase characters*/
      if(password.match(/[A-Z]+/)) {
        strength++;
      }

      /*contains at least one special character*/
      //if(password.match(/[*.!@#$%^&(){}[\]:;<>,.?\/~_+-=|\ ]/)){
        //strength++;
      //}

      //Si aucun critere n'est rempli, la force ne pouvant etre nulle on la met à 1
      if(strength==0){
        strength = 1
      }

      //Pour le pourcentage de la valeur on accepte une force nulle
      var pourcentageCouleur = (strength-1)*100/(state.nbStepsPassword-1)

      //Pour le pourcentage de progression du pwd il faut un minimum affucher, la force ne peut etre nulle
      var pourcentageProgressBar = strength*100/state.nbStepsPassword

      return {pourcentageCouleur: pourcentageCouleur, pourcentageProgressBar: pourcentageProgressBar}
    },

    getPasswordError: (state) => (password) => {
      var code = "";

      /*length 5 characters or more*/
      if(!(password.length >= 5)) {
        code="too_short"
      }

      /*contains lowercase characters*/
      else if(!(password.match(/[a-z]+/))) {
        code="no_lowercase"
      }

      /*contains digits*/
      else if(!(password.match(/[0-9]+/))) {
        code="no_digit"
      }

      /*contains uppercase characters*/
      else if(!(password.match(/[A-Z]+/))) {
        code="no_uppercase"
      }

      return code
    }
  }
})