<template>
<div class="max first_page">
  <div class="will-fadeIn">
    <h1 class="titre_accueil">{{ page_home().bloc1.titleP1 }}<span class="gras">{{ page_home().bloc1.titleBold }}</span>{{ page_home().bloc1.titleP2 }}</h1>
    <p class="paragraphe_accueil">{{ page_home().bloc1.desc }}</p>
    <router-link to="/produits">
      <div class="boutton_offre">
        <p class="texte_boutton_offre">{{ page_home().bloc1.bouton }}</p>
      </div>
    </router-link>
  </div>
  <div class="div_home_img1_rgpd will-fadeIn fadeFromRight">
      <img class="home_img1_rgpd" src="../assets/home_img1_rgpd.png">
    </div>
</div>
<!--
<div class="div_home_img1_rgpd will-fadeIn fadeFromRight">
  <img class="home_img1_rgpd" src="../assets/home_img1_rgpd.png">
</div>-->
<div class="second_page">
  <div class="div1_second_page will-fadeIn fadeFromRight">
    <div class="first_span_top_second_page">
      <h2 class="first_span_top_second_page_1">{{ page_home().bloc2.title }}</h2>
      <img src="../assets/white_nina.png">
    </div>
    <div></div>
    <div class="second_span_top_second_page">{{ page_home().bloc2.desc }}</div>
  </div>
  <div class="div2_second_page">
    <img  class="will-fadeIn" src="../assets/home_img2.png">
    <div></div>
    <div class="second_span_div2_second_page">
      <div class="s1_s2_d2_sp long_span will-fadeIn fadeFromRight">
        <div class="long_span_left table_cell_middle">
          <div class="long_span_left_in1">
            <div class="long_span_left_in2 long_span_left_in2_color1 table_cell_middle" :class="page_global().cards[0].favicon"></div>
          </div>
        </div>
        <div class="long_span_space_between"></div>
        <div class="table_cell_middle text_align_left">
          <div class="long_span_title">{{ page_home().cards[0].title }}</div>
          <div class="long_span_right_down">{{ page_home().cards[0].desc }}</div>
        </div>
      </div>
      <div class="s2_s2_d2_sp long_span will-fadeIn">
        <div class="long_span_left table_cell_middle">
          <div class="long_span_left_in1">
            <div class="long_span_left_in2 long_span_left_in2_color2 table_cell_middle" :class="page_global().cards[1].favicon"></div>
          </div>
        </div>
        <div class="long_span_space_between"></div>
        <div class="table_cell_middle text_align_left">
          <div class="long_span_title">{{ page_home().cards[1].title }}</div>
          <div class="long_span_right_down">{{ page_home().cards[1].desc }}</div>
        </div>
      </div>
      <div class="s3_s2_d2_sp long_span will-fadeIn fadeFromRight">
        <div class="long_span_left table_cell_middle">
          <div class="long_span_left_in1">
            <div class="long_span_left_in2 long_span_left_in2_color3 table_cell_middle" :class="page_global().cards[2].favicon"></div>
          </div>
        </div>
        <div class="long_span_space_between"></div>
        <div class="table_cell_middle text_align_left">
          <div class="long_span_title">{{ page_home().cards[2].title }}</div>
          <div class="long_span_right_down">{{ page_home().cards[2].desc }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="div3_second_page">
    <div class="text_align_left">
      <h2 class="div3_second_page_left1 will-fadeIn">{{ page_home().bloc4.title }}</h2>
      <div class="div3_second_page_left2 will-fadeIn">{{ page_home().bloc4.desc }}</div>
      <router-link to="/produits">
        <div class="boutton_offre will-fadeIn">
          <p class="texte_boutton_offre">{{ page_home().bloc4.bouton }}</p>
        </div>
      </router-link>
    </div>
    <div class="div3_second_page_space_between"></div>
    <img class="home_img3 will-fadeIn fadeFromRight" src="../assets/home_img3.png">
  </div>
  <div class="div4_second_page">
    <div>
      <div class="title_div4_second_page will-fadeIn">
        <img src="../assets/black_nina.png">
        <h2 class="title_div4_second_page_1">{{ page_home().bloc5.title }}</h2>
      </div>
      <div class="bar_div4_second_page">
        <span class="will-fadeIn" v-for="(favicon, indexFavicon) in page_home().bloc5.sub_titles" :key="indexFavicon">
          <i class="img_bar_div4_second_page" :class="page_global().bloc5.favicon[indexFavicon]"></i>
          <span class="text_bar_div4_second_page">{{ favicon }}</span>
        </span>
      </div>
    </div>
    <img class="home_img4_desk" src="../assets/home_img4_desk.png">
  </div>
</div>
</template>

<script>
import page_glb from "@/json/page_global.json";
import page_home from "@/json/page_home.json";

export default {
  name: 'Home',
  data(){
    return{
      page_glb: page_glb,
      page_home: page_home
    }
  },
  methods:{
    page_home(){
      return this.page_home[this.$store.state.codeLangue]
    },
    page_global(){
      return this.page_glb.global.home
    },
  },
  mounted(){
    this.$store.state.cliquable=true
  } 
}
</script>

<style scoped>
.long_span_title{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.gras{
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
}
.absolute{
  position: absolute;
}

.text_align_left{
  text-align: left;
}

.max{
  width: -webkit-fill-available;
  height: 100%;
}

/*La margin-top sera peut être à ajuster*/
.first_page{
  position:relative;
  background-color: #022873;
  padding-top: 216px;
  padding-left: 96px;
  color: #FFFFFF;
  text-align: left;
  min-height: 600px;
}

.titre_accueil{
  width: 622px;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
}

.paragraphe_accueil{
  width: 494px;
  margin-top: 24px;
  padding-bottom: 48px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.second_page{
  background-color: #FFFFFF;
}

.div1_second_page{
  margin-top: 120px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(229, 232, 247, 0.5);
  border-radius: 20px;
  display: table;
  width: -webkit-fill-available;
  padding-right: 64px;
  padding-left: 64px;
  text-align: left;
  /*1280-4*64 = 1024*/
  max-width: 1024px;
  transition: all.3s
}

.div1_second_page:hover{
  transform: scale(1.02)
}

.first_span_top_second_page{
  width: 411px;
  display: table-cell;
  vertical-align: middle;
}

.first_span_top_second_page_1{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  /* or 128% */


  color: #000000;
}

.second_span_top_second_page{
  max-width: 500px;
  display: table-cell;
  vertical-align: middle;

  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.div2_second_page{
  margin-top: 136px;
  margin-right: auto;
  margin-left: auto;
  /*1280-2*136 = 1008*/
  max-width: 1008px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.second_span_div2_second_page{
  width: 687px;
}

.long_span{
  width: 419px;
  height: 72px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  box-shadow: 0px 2px 10px #e0e0e0;
  border-radius: 10px;
  display: table;
  transition: all .3s
}

.long_span:hover{
  transform: scale(1.1)
}

.s1_s2_d2_sp{
  margin-top: 15px;
  margin-left: 112px;
}

.s2_s2_d2_sp{
  margin-top: 42px;
  margin-left: 266px;
}

.s3_s2_d2_sp{
  margin-top: 42px;
  margin-left: 96px;
}
.table_cell_middle{
  display: table-cell;
  vertical-align: middle
}

.table_cell{
  display: table-cell;
}

.long_span_left{
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
}

.long_span_right_down{
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #455A64;
}

.long_span_space_between{
  width: 34px;
}

.div3_second_page{
  margin-top: 160px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  /*1280-2*136 = 1008*/
  max-width: 1008px;
}

.div3_second_page:hover > img{
  transform: scale(1.05);
}

.home_img3{
  transform: scale(0.95);
  transition: all .3s
}

.div3_second_page_left1{
  width: 463px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: #000000;
}

.div3_second_page_left2{
  width: 500px;
  margin-top: 36px;
  margin-bottom: 36px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.div3_second_page_space_between{
  width: 140px;
}

.div4_second_page{
  margin-top: 157px;
  padding-bottom:40px;
  background: #022873;
  padding-left: 126px;
  color: #FFFFFF;
  text-align: left;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.title_div4_second_page{
  margin: 0px;
  padding-top: 72px;
}

.title_div4_second_page_1{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
}

.bar_div4_second_page{
  padding-top: 32px;
  display:flex;
  flex-wrap: wrap;
}

.img_bar_div4_second_page{
  font-size: 30px;
  padding: 9px;
  margin-right:8px;
}

.text_bar_div4_second_page{
  margin-right: 112px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.long_span_left_in1{
  margin-left: 24px;
  width: 34px;
  height: 34px;
  display: table;
}

.long_span_left_in2{
  border-radius: 50%
}

.long_span_left_in2_color1{
  color: #04D98B;
  background: rgba(4, 217, 139, 0.3);
}

.long_span_left_in2_color2{
  color: #E8505B;
  background: rgba(242, 80, 93, 0.5);
}

.long_span_left_in2_color3{
  background: rgba(2, 40, 115, 0.5);
}

.home_img4_desk{
  position: absolute;
  bottom: -42px;
  right: 0px;
}

.div_home_img1_rgpd{
  position: absolute;
  bottom: 0px;
  right: 5%;
}

@media screen and (min-width: 1600px) {


  .first_page{
    padding-left: 150px; 
  }

  .div4_second_page{
    padding-left: 150px; 
  }

  .img_bar_div4_second_page{
    padding-right: 18px;
  }

  .text_bar_div4_second_page{
    margin-right: 150px;
  }
}

@media screen and (max-width: 1100px){
  .div1_second_page{
    display: block;
    width: fit-content;
  }

  .div2_second_page{
    margin-top: 30px;
    flex-direction: column
  }

  .div3_second_page{
    margin-top: 80px;
    flex-direction: column
  }

  .home_img3{
    margin-top: 80px;
  }

  .div4_second_page{
    margin-top: 80px;
    padding-right: 296px;
  }

  .text_bar_div4_second_page{
    margin-right:20px;
  }

  .first_span_top_second_page{
    width:fit-content;
  }
}

@media screen and (max-width: 850px){
  .div1_second_page{
    width: 500px;
  }
}

@media screen and (max-width: 750px){
  .first_page{
    padding-right: 96px;
    padding-top: 150px;
  }

  .titre_accueil{
    width: fit-content;
  }

  .second_span_div2_second_page{
    width:fit-content;
  }

  .long_span{
    margin-left: 0px!important;
  }

  .first_span_top_second_page{
    width:fit-content;
  }

  .paragraphe_accueil{
    width: fit-content;
  }
}

@media screen and (max-width: 650px){
  .div4_second_page{
    height:550px;
    padding-right: 80px;
  }

  .bar_div4_second_page{
    flex-direction: column;
  }
}

@media screen and (max-width: 550px){
  .long_span{
    width: 300px;
  }

  .div1_second_page{
    width: 300px;
  }

  .div3_second_page_left1{
    width: 300px;
  }

  .div3_second_page_left2{
    width: 300px;
  }

  .first_page{
    padding-top: 120px;
  }

  .paragraphe_accueil{
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 500px){
  .home_img3{
    width: 300px;
  }

  .div4_second_page{
    padding-right:40px;
    padding-left:40px;
  }
}

@media screen and (max-width: 450px){
  .first_page{
    padding-right:20px;
    padding-left:20px;
  }

  .max{
    min-height: 670px;
  }
}

@media screen and (max-width: 400px){
  .div4_second_page{
    height: 660px;
  }
}

@media screen and (max-width: 1250px) and (max-height: 850px){
  .home_img1_rgpd{
    width: 500px;
  }
}

@media screen and (max-width: 950px) and (min-height: 851px){
  .home_img1_rgpd{
    width: 500px;
  }
}

@media screen and (max-width: 1350px) and (max-height: 700px){
  .home_img1_rgpd{
    width: 500px;
  }
}

@media screen and (max-width: 750px) and (max-height: 750px){
  .home_img1_rgpd{
    width: 400px;
  }
}

@media screen and (max-width: 1100px) and (max-height: 720px){
  .home_img1_rgpd{
    width: 300px;
  }
}

@media screen and (max-width: 650px){
  .home_img1_rgpd{
    width: 350px;
  }
}

@media screen and (max-width: 400px){
  .home_img1_rgpd{
    width: 300px;
  }
  .div_home_img1_rgpd{
    right: 0px;
  }
}

</style>

<style>

@keyframes shake {
    0% { left: -5px; }
    100% { right: -5px; }
}

.boutton_offre{
  background: #F2505D!important;
  padding-left: 10px;
  padding-right: 10px;
  min-width: 180px;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  cursor: pointer;
  transition: transform .2s;
}

.boutton_offre:hover{
  transform: scale(1.1);
}

.texte_boutton_offre{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  height: fit-content;
  margin: 12px;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.pointer{
  cursor: pointer;
}

.no_product{
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
}

/*GESTION DES TRANSITIONS*/
.slide-enter-active{
   -moz-transition-duration: .5s;
   -webkit-transition-duration: .5s;
   -o-transition-duration: .5s;
   transition-duration: .5s;
   -moz-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 1.0);
   -webkit-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 1.0);
   -o-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 1.0);
   transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 1.0);
}

.slide-leave-active{
   -moz-transition-duration: .5s;
   -webkit-transition-duration: .5s;
   -o-transition-duration: .5s;
   transition-duration: .5s;
   -moz-transition-timing-function: cubic-bezier(0.0, 0.0, 0.58, 1.0);
   -webkit-transition-timing-function: cubic-bezier(0.0, 0.0, 0.58, 1.0);
   -o-transition-timing-function: cubic-bezier(0.0, 0.0, 0.58, 1.0);
   transition-timing-function: cubic-bezier(0.0, 0.0, 0.58, 1.0);
}


.slide-enter-to, .slide-leave-from{
   max-height: 1000px;
   overflow: hidden;
}

.slide-enter-from, .slide-leave-to {
   overflow: hidden;
   max-height: 0px;
   padding-top: 0px!important;
   padding-bottom: 0px!important;
}

.slideShort-enter-active{
   -moz-transition-duration: .5s;
   -webkit-transition-duration: .5s;
   -o-transition-duration: .5s;
   transition-duration: .5s;
   -moz-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 0.2);
   -webkit-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 0.2);
   -o-transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 0.2);
   transition-timing-function: cubic-bezier(0.0, 0.0, 1.0, 0.2);
}

.slideShort-leave-active{
   -moz-transition-duration: .5s;
   -webkit-transition-duration: .5s;
   -o-transition-duration: .5s;
   transition-duration: .5s;
   -moz-transition-timing-function: cubic-bezier(0.0, 0.0, 0, 1.0);
   -webkit-transition-timing-function: cubic-bezier(0.0, 0.0, 0.0, 1.0);
   -o-transition-timing-function: cubic-bezier(0.0, 0.0, 0.0, 1.0);
   transition-timing-function: cubic-bezier(0.0, 0.0, 0.0, 1.0);
}


.slideShort-enter-to, .slideShort-leave-from{
   max-height: 400px;
   overflow: hidden;
}

.slideShort-enter-from, .slideShort-leave-to {
   overflow: hidden;
   max-height: 0px;
   padding-top: 0px!important;
   padding-bottom: 0px!important;
}

input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 10px;
  height: 10px;
  border: 1px solid #000000;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #42b983;;
    background-color: CanvasText;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

</style>