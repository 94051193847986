function getPositionToTop(element) {
    var yPosition = 0;

    //console.log(element)
    while(element) {
        //console.log(element.offsetTop + "/" + element.scrollTop + "/" + element.clientTop)
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
        //console.log(element)
    }

    return yPosition
}

function getElemDistance(elem) {
    var location = 0;
    if (elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
};

function isElementInView(element, fullyInView) {
    var pageTop = $(window).scrollTop();
    var pageBottom = pageTop + $(window).height();
    //var elementTop = $(element).offset().top;
    //var elementTop = getPositionToTop($(element)[0])
    var elementTop = getElemDistance($(element)[0])
    var elementBottom = elementTop + $(element).height();

    if (fullyInView === true) {
        return pageTop < elementTop && pageBottom > elementBottom;
    } else {
        //console.log(elementTop + "->" + pageBottom + "/" + elementBottom + "->" + pageTop)
        return elementTop < pageBottom && elementBottom > pageTop;
    }
}

function addFadeIn(repeat) {

    var classToFadeIn = ".will-fadeIn";
    $(classToFadeIn).each(function(index) {
        var isElementInViewB = isElementInView($(this), false);
        //console.log("element" + index + "is in view : " + isElementInViewB)
        if (isElementInViewB) {
            if (!$(this).hasClass("fadeInRight") && !$(this).hasClass("fadeInLeft")) {
                if ($(this).hasClass("fadeFromRight")){
                    $(this).addClass("fadeInRight");
                }
                else {
                    $(this).addClass("fadeInLeft");
                }
            }

            /*if(window.getComputedStyle($(this)[0]).getPropertyValue("opacity")==0){
                console.log(window.getComputedStyle($(this)[0]).getPropertyValue("opacity"))
                console.log($(this)[0])
                //$(this).style.opacity=1
                //$(this).style.opacity=1
            }*/
        } 
        else if (repeat) {
            $(this).removeClass("fadeInRight");
            $(this).removeClass("fadeInLeft");
        }
    });
}

function addFadeInFalse(){
    addFadeIn(false)
}

export default {
    getPositionToTop, getElemDistance, isElementInView, addFadeIn, addFadeInFalse
}