<template>
  <div v-if="this.$store.state.cliquable==false" :class="{ wait: !this.$store.state.cliquable }">
    <!--<img class="load_gif" src="./assets/load3.gif">-->
    <div class="loading"> 
      <svg width="30px" height="30px">
        <polyline id="back" points="2 12 6 18 14 6 10 12 8 9 14 18 24 3"></polyline>
        <polyline id="front" points="2 12 6 18 14 6 10 12 8 9 14 18 24 3"></polyline>
        <polyline id="load_back" points="2 28 24 28"></polyline>
        <polyline id="load_front" points="2 28 24 28"></polyline>
      </svg>
    </div>
  </div>
  <!--<img v-if="this.$store.state.cliquable==false" class="load2_gif" src="./assets/load2.gif">-->
  <div v-on:scroll.passive="updateScroll()" style="height:100%" @click="verifierValiditeSession">
    <div id="nav" v-bind:class="{ disabledLinks: !$store.state.cliquable }">
      <router-link class="left" to="/"><img class="nav_logo" src="./assets/nav_logo.png"></router-link>
      <div class="nav_svg" @click="nav_is_displayed=!nav_is_displayed"> 
        <svg id="svg_nav" width="30px" height="30px">
          <path id="up" d="M 6, 2 L 28, 2, M 2, 10 L 24, 10,  M 6, 18 L 28, 18;">
            <animate id="go_to_open" begin="indefinite" attributeName="d" dur="1s" fill="freeze" values="M 9, 2 L 25, 18, M 17, 10 L 17, 10, M 9, 18 L 25, 2; M 6, 10 L 28, 10, M 17, 10 L 17, 10,  M 6, 10 L 28, 10; M 6, 10 L 28, 10, M 6, 10 L 28, 10,  M 6, 10 L 28, 10; M 6, 2 L 28, 2, M 6, 10 L 28, 10,  M 6, 18 L 28, 18;M 6, 2 L 28, 2, M 2, 10 L 24, 10,  M 6, 18 L 28, 18"></animate>
            <animate id="go_to_close" begin="indefinite" attributeName="d" dur="1s" fill="freeze" values="M 6, 2 L 28, 2, M 2, 10 L 24, 10,  M 6, 18 L 28, 18; M 6, 2 L 28, 2, M 6, 10 L 28, 10,  M 6, 18 L 28, 18;  M 6, 10 L 28, 10, M 6, 10 L 28, 10,  M 6, 10 L 28, 10;  M 6, 10 L 28, 10, M 17, 10 L 17, 10,  M 6, 10 L 28, 10; M 9, 2 L 25, 18, M 17, 10 L 17, 10, M 9, 18 L 25, 2"></animate>
          </path>
        </svg>
      </div>
      <span class="right_nav" @click="nav_is_displayed=false" :class="[ nav_is_displayed ? 'nav_is_displayed_css' : 'nav_is_not_displayed_css' ]">
        <span class="display_flex_media">
          <!--
          <span class="display_products" @mouseover="show_langues=true" @mouseleave="show_langues=false">
            <img id="flag_image" class="flag_image" :src="require('./assets/' + $store.state.flag)">
            <div class="dropdown" v-if="show_langues">
              <div class="dropdown_div_in no_margin pointer" @click="changerLangue(lg.codeLangue, lg.flag)" v-for="(lg, indexLangue) in page_glb.global.langues" :key="indexLangue">{{ lg.langue }} - {{ lg.codeLangue}}</div>
            </div>
          </span>-->
          <span class="nav_phone_container false_span">
            <a href="tel:0184608460" class="nav_phone_up">01 84 60 84 60</a>
            <span class="nav_phone_down">Appel non surtaxé, Lun-Ven 9h-18h</span>
          </span>
          <router-link to="/strategie_rgpd">{{ page_app().nav.strategie_rgpd }}</router-link>
          <router-link to="/produits">{{ page_app().nav.produits.global }}</router-link>
          <!--<router-link to="/produits" @mouseover="show_offres=true" @mouseleave="show_offres=false">
            <span class="display_products">
              <span>{{ page_app().nav.produits.global }}</span>
              <div class="dropdown" v-if="show_offres">
                <div class="dropdown_div_in">
                  <router-link class="no_margin" to="/produits/rgpd">{{ page_app().nav.produits.rgpd }}</router-link>
                </div>
                <div class="dropdown_div_in">
                  <router-link class="no_margin" to="/produits/conformite">{{ page_app().nav.produits.mediation }}</router-link>
                </div>
              </div>
            </span>
          </router-link>-->
          <router-link v-if="this.$store.state.currentUser.mail" to="/compte">{{ page_app().nav.compte }}</router-link>
          <router-link to="/panier" v-if="this.$route.fullPath=='/panier/commande'"><span class="rectangle_panier"><span>{{ Number(this.$store.getters.getTTCPanier).toFixed(2) }}€</span><i class="fa panier_icon">&#xf07a;</i></span></router-link>
          <router-link to="/panier" v-else><span class="rectangle_panier"><span>{{ Number(this.$store.getters.getPrixPanierNoDiscount).toFixed(2) }}€</span><i class="fa panier_icon">&#xf07a;</i></span></router-link>
          <router-link to="/connexion" v-if="!this.$store.state.currentUser.mail" @click="this.$store.state.erreurConnexion = false">{{ page_app().nav.connexion }}</router-link>
          <router-link v-if="this.$store.state.currentUser.mail" to="/connexion" @click="deconnecter"><i class="fa white">&#xf2f5;</i></router-link>
        </span>
        <!--
        <span v-if="this.$store.state.currentUser.mail" class="display_flex_media">
          <router-link to="/compte">Mon compte</router-link>
          <router-link to="/panier"><span class="rectangle_panier"><span>{{ this.$store.getters.getPrixPanier }}€</span><i class="fa panier_icon">&#xf07a;</i></span></router-link>
          <router-link to="/" @click="deconnecter"><i class="fa white">&#xf2f5;</i></router-link>
        </span>-->
      </span>
    </div>
    <span id="router-view" @click="nav_is_displayed=false">
      <router-view />
    </span>
    <!--<div v-if="($route.path=='/index.html')" class="fullPageScreenIndexHtml"></div>-->
    <div class="footer">
      <div class="footer_first_div">
        <img src="./assets/footer_logo.png" class="footer_img">
        <div>
          <div class="footer_title">{{ page_app().footer.bloc1.title }}</div>
          <div @click="$router.push('/document/' + desc.link)" class="footer_link pointer" v-for="(desc, indexDesc) in page_app().footer.bloc1.desc" :key="indexDesc">{{ desc.text }}</div>
        </div>
        <div>
          <div class="footer_title">{{ page_app().footer.bloc2.title }}</div>
          <div class="footer_link pointer" @click="this.$router.push('/contact')">{{ page_app().footer.bloc2.contact }}</div>
        </div>
        <div class="footer_last_div footer_link">{{ page_app().footer.bloc3.desc }}</div>
      </div>
      <div class="copyright">{{ page_app().footer.copyright }}</div>
    </div>
    <div v-show="topScroll>500" @click="backToTop" class="back_to_top pointer"><i class="fas fa-angle-up back_to_top_favicon"></i></div>
  </div>
</template>

<script>
import page_glb from "@/json/page_global.json";
import page_this from "@/json/page_app.json";
import fadeIn from "@/js/fadeIn.js";

export default{
  data(){
    return{
      show_offres: false,
      topScroll: 0,
      nav_is_displayed: false,
      page_glb: page_glb,
      page_this: page_this,
      show_langues: false,
      fadeIn:fadeIn
    }
  },
  methods:{
    page_app(){
      return this.page_this[this.$store.state.codeLangue]
    },
    changerLangue(code, flag){
      this.$store.state.codeLangue = code
      this.$store.state.flag = flag
      document.documentElement.setAttribute("lang", code);
    },
    display_nav_media(width){
      var largeur = window.innerWidth;
      if(largeur<=1150){
        var navs = document.getElementsByClassName("right_nav")
        for(var i=0; i<navs.length; i++) {
          navs[i].style.width = width;
        }
      }
    },

    updateScroll: function(){
      this.topScroll = window.scrollY
    },

    backToTop(){
      var options={
        top: 0,
        left: 0,
        behavior: 'smooth'
      }

      window.scrollTo(options)
    },

    deconnecter(){
      this.$store.dispatch('logoutUser')
      this.$store.dispatch("viderPanier")
      this.$router.push('/connexion')
    },

    donnerPage(url){
      var page = this.$route.path
      if(page!=url){
        this.$store.state.cliquable=false
      }
    },

    verifierValiditeSession(){
      var lastClick = this.$store.getters.getCookies.lastClick
      /*
      if(window.localStorage.lastClick){
        var currentDate = new Date();
        var diff = (currentDate - new Date(window.localStorage.lastClick))/1000

        //Si la diff est superieure a 1h (3600s) et qu'il est connecté alors on le déconnecte
        if(diff>3600 && JSON.parse(window.localStorage.currentUser).mail){
          //On met un message d'erreur pour indiquer que la session s'est terminée
          this.$store.state.erreurConnexion = "session_expired"
          this.deconnecter()
        }
        else{
          window.localStorage.lastClick = currentDate
          document.cookie = "lastClick=" + currentDate
        }
      }
      else{
        window.localStorage.lastClick = new Date()
      }*/

      if(lastClick){
        var currentDate = new Date();
        var diff = (currentDate - new Date(lastClick))/1000

        //Si la diff est superieure a 1h (3600s) et qu'il est connecté alors on le déconnecte
        if(diff>3600 && JSON.parse(window.localStorage.currentUser).mail){
          //On met un message d'erreur pour indiquer que la session s'est terminée
          this.$store.state.erreurConnexion = "session_expired"
          this.deconnecter()
        }
        else{
          document.cookie = "lastClick=" + currentDate
        }
      }
      else{
        document.cookie = "lastClick=" + new Date();
      }
    },

    update_nav_svg_path(){
      if(this.nav_is_displayed){
        //this.display_nav_media('300px')
        document.getElementById("go_to_close").beginElement()
        console.log('go_to_close')
      }
      else{
        //this.display_nav_media('0px')
        document.getElementById("go_to_open").beginElement()
        console.log('go_to_open')
      }
    }
  },

  watch:{
    nav_is_displayed() {
      this.update_nav_svg_path()
    }
  }, 

  created () {

    window.addEventListener('DOMContentLoaded', fadeIn.addFadeInFalse);
    window.addEventListener('DOMContentLoaded', this.updateScroll);

    window.addEventListener('load', fadeIn.addFadeInFalse);
    window.addEventListener('load', this.updateScroll);

  },

  mounted(){
    //window.addEventListener('scroll', fadeIn.addFadeInFalse;);
    window.addEventListener('scroll', this.updateScroll);
 
    //console.log("mounted")
    this.verifierValiditeSession()
    //console.log(passwordScript)
    this.$store.dispatch("loadUser");
    this.$store.dispatch("loadPanier");
    this.$store.dispatch("loadIntent");
  },

  beforeDestroy(){
    if(this.$store.state.currentUser.mail!="rgpd@weboblige.com"){
      if(this.$store.state.currentUser.mail!=window.localStorage.currentUser.mail){
        this.deconnecter()
      }
    }
  }
}
</script>


<style>
html {
  scroll-behavior: auto!important;
}

.will-fadeIn{
  opacity:0
}

.fadeInRight {
  animation: fadeInRight .5s ease .4s both;
}
@media (prefers-reduced-motion) {
  .fadeInRight .animated {
    animation: unset !important;
    transition: none !important;
  }
}

.fadeInLeft {
  animation: fadeInLeft .5s ease .4s both;
}
@media (prefers-reduced-motion) {
  .fadeInLeft .animated {
    animation: unset !important;
    transition: none !important;
  }
}



@keyframes fadeInRight {
  from {
    /*opacity: 0;*/
    transform: translate3d(20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}



.nav_svg{
  z-index: 1;
  display:none;
}

.nav_svg>svg>path{
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.nav_svg>svg>path{
  stroke: #fff;
}

svg#svg_nav{
  height: 21px;
  width: 31px
}

.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(2);
}
.loading>svg>polyline{
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loading>svg>polyline#front{
  stroke: #42b983;
  stroke-dasharray: 65;
  stroke-dashoffset: 130;
  animation: dash 4s linear infinite;
}
.loading>svg>polyline#back, .loading>svg>polyline#load_back{
  stroke: #42b983;
  opacity:0.3;
}
.loading>svg>polyline#load_front{
  stroke: #42b983;
  stroke-dasharray: 22;
  stroke-dashoffset: 44;
  animation: dash 2s linear infinite;
}

.loading>svg>polyline#back{
  stroke: #42b983;
  opacity:0.3;
}
        
@keyframes dash {
  62.5%{
    opacity: 1
  }
  to {
    stroke-dashoffset: 0
  }
}

.load2_gif {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1;
  overflow: hidden;
  opacity: 0.6
}

.back_to_top{
  color: #022873;
  border-radius: 50%;
  display: flex;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border: 3px solid #022873;
  position: fixed;
  bottom: 60px;
  right: 60px;
  align-items: center;
  justify-content: center;
}

.back_to_top_favicon{
  font-size: 30px;
  font-weight: 600;
}

/*
.back_to_top:hover .fa-angle-up:before{
    content: "\f102";
}
*/

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height:100%;
  min-height:100%;
}

#nav {
  padding: 35px;
  background: #022873;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 2;
  padding-left: 64px;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

#nav a, .false_span{
  color: #FFFFFF;
  margin: 15px;
  text-decoration: none;
}

#nav a.left{
  margin: 0px;
}

/* ICI, quand on est sur le lien la couleur change */
/*On distingue router-link-active de router-link-exact-active, on peut enlever le vert de cgv et conformite*/
#nav a.router-link-active {
  color: #42b983;
  /*text-decoration: underline;*/
}

.disabledLinks a {
  pointer-events: none;
}

.disabledLinks {
  cursor: wait;
  overflow: hidden;
}

html, body{
  margin: 0px;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
}

router-view{
  min-height: 100%;
}

.wait {
  height: 100%;
  width: 100%;
  background-color: #022873;
  position: fixed;
  z-index: 2;
  overflow: hidden;
  cursor: wait;
}
</style>

<style scoped>
.nav_is_displayed_css{
  width: 300px!important;
}

.display_flex_media{
  display: flex;
  align-items: center;
}

.nav_phone_container{
  display: flex;
  flex-direction: column;
  text-align: left;
  height: fit-content;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav_phone_up{
  font-size: 14px;
  margin: 0px!important;
}

.nav_phone_down{
  font-size: 10px;
}

.flag_image{
  width: 20px;
  border: 1px solid #ffffff
}

.footer_img{
  max-width: fit-content;
  margin-left: 50px;
}

.right_nav{
  transition: all 1s;
  display: flex;
  align-items: center;
}

.traits_nav_media{
  z-index: 1;
  font-size: 30px;
  display:none;
}

.rectangle_panier{
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.panier_icon{
  margin-left: 10px;
}

.display_products{
  position: relative;
  display: inline-block;
}

.load_gif {
  width: 64px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

.white{
  color: #FFFFFF!important;
}

i{
  font-size: 20px;
}

.footer{
  background-color: #F2F3FB;
  padding-right: 115px;
  padding-left: 115px;
  padding-top: 48px;
  text-align:left
}

.footer_first_div{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer_first_div > div{
  margin: 20px;
}

.footer_last_div{
  width: 193px;
}

.footer_title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #022873;
  margin-bottom: 23px;
}

.footer_link{
  margin-bottom: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.copyright{
  margin-top: 45px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 38px;

  color: #022873;
}
/*
.nav_logo{
  width: 54px;
  height: 53px;
}
*/

.dropdown{
  position: absolute;
  text-align: left;
  min-width: max-content;
  background: #022873;
  padding-left: 10px;
  padding-right: 10px;
  /*margin-left = -(padding-left+border-left)*/
  /*margin-left = -(10+2)*/
  /*margin-left = -12*/
  margin-left: -12px;
  border-bottom-left-radius: 8px;
  padding-top: 5px;
  border-left: 2px solid #42b983;
  border-bottom: 2px solid #42b983;
}

.dropdown_div_in{
  margin-bottom: 5px;
}

.no_margin{
  margin: 0px!important;
}

@media screen and (min-width: 1600px) {
  #nav{
    padding-right: 150px;
    padding-left: 150px;
  }

  .footer{
    padding-right: 150px;
    padding-left: 150px;
  }
}

@media screen and (max-width: 1250px){
  .back_to_top{
    bottom: 30px;
    right: 30px;
  }
}
@media screen and (min-width: 1151px){
  .right_nav{
    width: fit-content!important;
  }
}
@media screen and (max-width: 1150px) {

  .right_nav{
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 0px;
    padding-top: 117px;
    flex-direction: column;
    background: #022873;
    overflow-y:scroll;
  }

  .nav_phone_container{
    text-align: center;
  }

  .display_flex_media{
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  .traits_nav_media{
    display:block
  }

  .nav_svg{
    display:block
  }

}

@media screen and (max-width: 700px) {
  .footer_first_div{
    flex-direction: column;
    align-items: center;
  }

  .footer_first_div > div{
    text-align: center
  }
}

@media screen and (max-width: 500px) {
  .footer{
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 450px) {
  #nav{
    padding-left: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 400px) {
  .nav_is_displayed_css{
    width: 100%!important;
  }
}

@media screen and (max-height: 500px) {
  #nav{
    padding-left: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 35px;
  }
}
</style>